import { useState } from 'react';
import { Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import { useSendbirdService } from 'services/SendbirdService';
import { AuthGuard } from 'components/authGuard';
import { Auth } from 'routes/path';
import LineNotifyList from './components/line-notify/line-notify-list';

const { Badge, Popover, IconButton, useTheme } = Mui;

export const LineNotifyPopover = () => {
  const theme = useTheme();
  const { lineChannelUnreadCount } = useSendbirdService();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AuthGuard allMatch={true} allowedPermissions={[Auth.LINE_CHAT_READ]}>
      <IconButton
        onClick={handleOpen}
        sx={{
          mr: '8px',
        }}
      >
        <Badge badgeContent={lineChannelUnreadCount} color="error">
          <Icon
            name={SvgName.Line}
            sx={{
              background: theme.palette.grey[600],
              cursor: 'pointer',
              width: '27px',
              height: '27px',
            }}
          />
        </Badge>
      </IconButton>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <LineNotifyList />
      </Popover>
    </AuthGuard>
  );
};
