import { createContext, useContext, useState, useEffect } from 'react';
import SendBird, { ConnectionState } from '@sendbird/chat';
import {
  GroupChannelModule,
  GroupChannelHandler,
} from '@sendbird/chat/groupChannel';
import {
  OpenChannelModule,
  OpenChannelHandler,
} from '@sendbird/chat/openChannel';
import { Auth } from 'routes/path';
import { useGetNotifications } from 'hooks/query/notificationCenter';
import { SendbirdProvider } from '@sendbird/uikit-react/SendbirdProvider';
import { useLoginService } from './LoginService';
import { ISendbird } from './sendbird-service.type';
import {
  CUSTOMER_TYPE,
  LINE_CHANNEL_TYPES,
  myColorSet,
  NOTIFICATION_OPEN_CHANNEL_URL,
  PROGRESSING_TICKET_ID,
  SENDBIRD_APP_ID,
  UNREAD_COUNT_KEYS,
} from './sendbird-service.constants';
const { CUSTOMER_CHANNEL, TICKET_CHANNEL, TEACHER_CHANNEL, LINE_CHANNEL } =
  UNREAD_COUNT_KEYS;

export const SendbirdInitContext = createContext({} as ISendbird);

const sbInstance = SendBird.init({
  localCacheEnabled: true,
  appId: SENDBIRD_APP_ID,
  modules: [new GroupChannelModule(), new OpenChannelModule()],
});

const DISCONNECT_DURATION = 60 * 1000;

export const SendbirdInitProvider = ({ children }: { children: any }) => {
  const { loginUser } = useLoginService();
  const permissions = loginUser?.permissions || [];
  const includeAuth = (auth: string[]) =>
    auth.some((item: any) => permissions.includes(item));
  const staffNumber = loginUser?.staffNumber || '';
  const isLogin = !!staffNumber;
  const channelTypesId = loginUser?.channelTypes?.map(item => item.id) || [];
  const hasTeacherTalkAuth = includeAuth([Auth.TEACHER_TALK]);
  const hasParentMessageEditAuth = includeAuth([Auth.PARENT_MESSAGE_EDIT]);

  const [unreadCounts, setUnreadCounts] = useState({
    [CUSTOMER_CHANNEL]: 0,
    [TICKET_CHANNEL]: 0,
    [TEACHER_CHANNEL]: 0,
    [LINE_CHANNEL]: 0,
  });

  const connectSendbird = async () => {
    if (sbInstance.connectionState !== ConnectionState.OPEN) {
      try {
        await sbInstance.connect(staffNumber, loginUser?.IMAccessToken);
      } catch (error) {
        console.error('Error connecting to Sendbird:', error);
      }
    }
  };

  const {
    data: notificationList,
    isLoading: isNotificationLoading,
    refetch: refetchNotificationList,
  } = useGetNotifications({ skip: 0, limit: 5 }, isLogin);

  const createUnreadCounts =
    (filters: string[], key: keyof typeof unreadCounts) => async () => {
      if (filters.length === 0) return;
      try {
        const count = await sbInstance.groupChannel.getTotalUnreadMessageCount({
          channelCustomTypesFilter: filters,
        });
        setUnreadCounts(prev => ({ ...prev, [key]: count }));
      } catch (error) {
        console.error(`Error get ${key} count`, error);
      }
    };

  const getTeacherUnreadCount = createUnreadCounts(
    channelTypesId,
    TEACHER_CHANNEL,
  );
  const getCustomerUnreadCount = createUnreadCounts(
    [CUSTOMER_TYPE],
    CUSTOMER_CHANNEL,
  );
  const getTicketUnreadCount = createUnreadCounts(
    [PROGRESSING_TICKET_ID],
    TICKET_CHANNEL,
  );
  const getLineUnreadCount = createUnreadCounts(
    LINE_CHANNEL_TYPES,
    LINE_CHANNEL,
  );

  useEffect(() => {
    if (!sbInstance || !staffNumber || !loginUser?.IMAccessToken) return;
    let disconnectTimeout: NodeJS.Timeout;
    let hasDisconnected = false;

    const initialize = async () => {
      await connectSendbird();

      if (hasTeacherTalkAuth) await getTeacherUnreadCount();
      if (hasParentMessageEditAuth) await getCustomerUnreadCount();
      await getTicketUnreadCount();
      await getLineUnreadCount();

      const groupChannelHandler = new GroupChannelHandler({
        onChannelChanged: async channel => {
          if (
            channelTypesId.includes(channel.customType) &&
            hasTeacherTalkAuth
          ) {
            await getTeacherUnreadCount();
          }
          if (
            channel.customType === CUSTOMER_TYPE &&
            hasParentMessageEditAuth
          ) {
            await getCustomerUnreadCount();
          }
          if (channel.customType === PROGRESSING_TICKET_ID) {
            await getTicketUnreadCount();
          }
          if (LINE_CHANNEL_TYPES.includes(channel.customType)) {
            await getLineUnreadCount();
          }
        },
      });

      sbInstance?.groupChannel.addGroupChannelHandler(
        SENDBIRD_APP_ID,
        groupChannelHandler,
      );

      const channel = await sbInstance.openChannel.getChannel(
        NOTIFICATION_OPEN_CHANNEL_URL,
      );
      await channel.enter();

      const openChannelHandler = new OpenChannelHandler({
        onMessageReceived: (channel, message) => {
          const receiver = JSON.parse(message.data);
          const isReceiver = receiver?.receivers?.includes(staffNumber);
          if (isReceiver) {
            refetchNotificationList();
          }
        },
      });

      sbInstance?.openChannel.addOpenChannelHandler(
        NOTIFICATION_OPEN_CHANNEL_URL,
        openChannelHandler,
      );

      return () => {
        sbInstance?.groupChannel.removeGroupChannelHandler(SENDBIRD_APP_ID);
        sbInstance?.openChannel.removeOpenChannelHandler(
          NOTIFICATION_OPEN_CHANNEL_URL,
        );
      };
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        disconnectTimeout = setTimeout(() => {
          sbInstance.disconnectWebSocket();
          hasDisconnected = true;
        }, DISCONNECT_DURATION);
      } else {
        clearTimeout(disconnectTimeout);

        if (hasDisconnected) {
          initialize();
          hasDisconnected = false;
        }
      }
    };

    initialize();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(disconnectTimeout);
    };
  }, [sbInstance, staffNumber, loginUser?.IMAccessToken]);

  const value: ISendbird = {
    sendbirdAccessToken: loginUser?.IMAccessToken || '',
    customerChannelUnreadCount: unreadCounts[CUSTOMER_CHANNEL],
    ticketChannelUnreadCount: unreadCounts[TICKET_CHANNEL],
    teacherChannelUnreadCount: unreadCounts[TEACHER_CHANNEL],
    lineChannelUnreadCount: unreadCounts[LINE_CHANNEL],
    sbInstance,
    myColorSet,
    notificationList: notificationList?.notifications || [],
    notifyCount: notificationList?.unreadCount || 0,
    isNotificationLoading,
    refetchNotificationList,
  };

  return (
    <SendbirdProvider
      appId={SENDBIRD_APP_ID}
      userId={loginUser?.staffNumber || ''}
      colorSet={myColorSet}
      accessToken={loginUser?.IMAccessToken || ''}
    >
      <SendbirdInitContext.Provider value={value}>
        {children}
      </SendbirdInitContext.Provider>
    </SendbirdProvider>
  );
};

export const useSendbirdService = () => useContext(SendbirdInitContext);
