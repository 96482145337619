// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import {
  useOffSetTop,
  useResponsive,
  cssStyles,
  Image,
  Iconify,
  IconButtonAnimate,
} from '@onedesign/ui';
// config
import { useMemo } from 'react';
import { ThemeMode } from 'theme/types';
import { LanguageSwitcher } from 'components/ChangeLanguage';
import { HEADER, NAVBAR } from '../../../config';
//

import AccountPopover from './AccountPopover';
import { TicketChannelPopover } from './TicketChannelPopover';
import { ChannelNotifyPopover } from './ChannelNotifyPopover';
import { TicketNotifyPopover } from './TicketNotifyPopover';
import { LineNotifyPopover } from './LineNotifyPopover';
import logoIcon from '@/favicon.svg';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: prop =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const UserNameStyles = styled('div')(({ theme }) => ({
  color:
    theme.palette.mode === ThemeMode.Dark
      ? theme.palette.grey[100]
      : theme.palette.grey[900],
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  loginUser: any;
  setLoginUser: (arg: any) => void;
};

export default function Header({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  loginUser,
  setLoginUser,
}: Props) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const userName = useMemo(() => {
    return loginUser?.name;
  }, [loginUser]);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && (
          <Image src={logoIcon} sx={{ width: '50px' }} />
        )}
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <UserNameStyles>Hi, {userName}</UserNameStyles>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1 }}>
          <LanguageSwitcher dark />
          {loginUser && (
            <>
              <TicketNotifyPopover />
              <ChannelNotifyPopover />
              <TicketChannelPopover />
              <LineNotifyPopover />
              <AccountPopover
                loginUser={loginUser}
                setLoginUser={setLoginUser}
              />
            </>
          )}
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
