import { useState } from 'react';
import '@sendbird/uikit-react/dist/index.css';
import { useSendbirdService } from 'services/SendbirdService';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useLoginService } from 'services/LoginService';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';
import ChannelListUI from './ChannelListUI';

const SendbirdTicketChannel = () => {
  const [currentChannelSelected, setCurrentChannelSelected] =
    useState<GroupChannel>();
  const { loginUser } = useLoginService();
  const { sendbirdAccessToken } = useSendbirdService();

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <ChannelListProvider
      queries={{
        channelListQuery: {
          includeEmpty: true,
          customTypesFilter: [import.meta.env.VITE_PROGRESSING_TICKET_ID],
        },
      }}
      disableAutoSelect={true}
      isTypingIndicatorEnabled={true}
    >
      <ChannelListUI
        setCurrentChannelSelected={setCurrentChannelSelected}
        currentChannelSelected={currentChannelSelected}
      />
    </ChannelListProvider>
  );
};

export default SendbirdTicketChannel;
