export enum SENDBIRD_CHANNEL_TYPE {
  TEACHER_CHAT_ONE_TO_ONE = 'teacher_chat_one_to_one',
}

export enum ETicketLogType {
  CREATE = 'create', // 建立案件
  EDIT = 'edit', // 編輯案件內容
  TRANSFER = 'transfer', // 轉交案件
  CLOSE = 'close', // 案件結束
}

export const ESendbirdTicketLogTypeLabel = {
  [ETicketLogType.CREATE]: '建立案件',
  [ETicketLogType.EDIT]: '編輯案件內容',
  [ETicketLogType.TRANSFER]: '轉交案件',
  [ETicketLogType.CLOSE]: '案件結束',
};

export enum EChannelTargetType {
  CUSTOMER = 'customer',
  TEACHER = 'teacher',
}

export enum ELineChannelLogType {
  ACCEPT = 'accept',
  TRANSFER = 'transfer',
  INVITE = 'invite',
  LEAVE = 'leave',
  UPDATE_CHANNEL_NAME = 'updateChannelName',
  BIND = 'bind',
  UNBIND = 'unbind',
  JOIN = 'join',
  CLOSED = 'closed',
}

export const ELineChannelLogTypeLabel = {
  [ELineChannelLogType.ACCEPT]: '開啟談話',
  [ELineChannelLogType.TRANSFER]: '轉交談話',
  [ELineChannelLogType.INVITE]: '邀請人員',
  [ELineChannelLogType.LEAVE]: '移除人員',
  [ELineChannelLogType.UPDATE_CHANNEL_NAME]: '編輯聊天室名稱',
  [ELineChannelLogType.BIND]: '會員綁定',
  [ELineChannelLogType.UNBIND]: '會員解綁',
  [ELineChannelLogType.JOIN]: '加入聊天室',
  [ELineChannelLogType.CLOSED]: '關閉聊天室',
};
