import { useState } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import background from 'assets/images/background.png';
import { styled, useTheme } from '@mui/material/styles';
import { Mui } from '@onedesign/ui';
import logo from 'assets/images/login-logo.svg';
import enLogo from 'assets/images/login-logo-en.svg';
import { useSnackbar } from 'notistack';
import { useLoadingService } from 'services/LoadingService';
import { ThemeMode } from 'theme/types';
import axios from 'plugins/api/axios';
import cookies, { EXAM_ROLE, KeyCookie } from 'plugins/cookie/cookie';
import path from 'routes/path';
import { routerTo } from 'utils/helpr/permission';
import { t } from 'utils/i18n';
import { LanguageSwitcher } from 'components/ChangeLanguage';
import { changeByLanguageSystem, SwitchLanguageValueType } from 'utils/logo';
import { useLoginService } from 'services/LoginService';

const { Box, Button, TextField } = Mui;
const { backstage, sendbird } = axios;

// ----------------------------css---------------------------------------

const LoginStyles = styled(Box)({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
});

const LoginBoxStyles = styled(Box)(() => ({
  display: 'flex',
  width: '60%',
  maxWidth: '860px',
  borderRadius: '10px',
  overflow: 'hidden',
}));

export const StyledChangeLanguageBox = styled(Box)({
  position: 'absolute',
  right: '1.5rem',
  top: '1.5rem',
});

const LoginIntroStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40%',
  maxWidth: '300px',
  padding: '0 10px',
  backgroundColor: '#E4E7EC',
  '& span': {
    color: theme.palette.grey[700],
    fontSize: '20px',
    margin: '20px 0',
  },
}));

const LoginMainStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  padding: '40px 70px',
  backgroundColor:
    theme.palette.mode === ThemeMode.Dark
      ? theme.palette.grey[800]
      : theme.palette.grey[100],
  '& > h2': {
    color:
      theme.palette.mode === ThemeMode.Dark
        ? theme.palette.grey[100]
        : theme.palette.grey[700],
    margin: '12px 0',
    fontWeight: 700,
  },
  '& > div, & > button': {
    margin: '12px 0',
    width: '100%',
  },
}));

const OperateStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.palette.grey[600],
  padding: '16px 0',
  borderTop: `1px solid ${theme.palette.grey[500_24]}`,
  cursor: 'default',
}));

// const CheckBoxStyles = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[800],
// }))

// ----------------------------------------------------------------------

export default () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useLoadingService();
  const { setLoginUser } = useLoginService();
  const [staffNumber, setStaffNumber] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const theme = useTheme();
  const icons: SwitchLanguageValueType<string> = {
    logoZh: logo,
    logoEn: enLogo,
  };

  const handleClickLogin = async (event: any) => {
    if (staffNumber && password) {
      if (event.keyCode === 13 || event.type === 'click') {
        showLoading();

        const response = await backstage.getAuthToken({
          staffNumber: staffNumber.trim(),
          password,
        });

        if (response.status === 'success') {
          cookies.set(KeyCookie.oneClassMMSClient, response.data);
          cookies.setNaniOneClassCookie(KeyCookie.examRole, EXAM_ROLE.Advisor);
          const oneclassToken = cookies.get(KeyCookie.oneClassClient);
          if (!oneclassToken) {
            cookies.setNaniOneClassCookie(
              KeyCookie.oneClassClient,
              JSON.stringify({
                code: 'SUCCESS',
                jwt: response.data?.accessToken,
              }),
            );
          }
          const res = await backstage.getMe();
          const IMUser = await sendbird.getIMUser({
            oneClubId: staffNumber,
          });

          if (res.status === 'success') {
            const currentData = res.data;
            setLoginUser({
              ...currentData,
              IMAccessToken: IMUser.data?.accessToken,
            });
            routerTo(currentData.permissions, navigate);
          }

          if (res.status === 'failure') {
            const error = res.error;
            enqueueSnackbar(`${error.errorCode}_${error.message}`, {
              variant: 'error',
            });
          }
        } else if (response.status === 'failure') {
          const error = response.error;

          error &&
            enqueueSnackbar(`${error.errorCode}_${error.message}`, {
              variant: 'error',
            });
        }

        hideLoading();
      }
      return;
    }
    if (!staffNumber) {
      setStaffNumber('');
    }
    if (!password) {
      setPassword('');
    }
  };

  return (
    <LoginStyles>
      <StyledChangeLanguageBox>
        <LanguageSwitcher />
      </StyledChangeLanguageBox>

      <LoginBoxStyles>
        <LoginIntroStyles>
          <img src={changeByLanguageSystem<string>(icons)} alt="oneClub" />
        </LoginIntroStyles>
        <LoginMainStyles>
          <h2>{t('login.adminLogin', '管理者登入')}</h2>
          <TextField
            placeholder={t('login.managerAccount', '管理者帳號')}
            onChange={event => setStaffNumber(event.target.value)}
            helperText={
              staffNumber !== undefined && !staffNumber
                ? t('login.accountNotEntered', '帳號未輸入')
                : ''
            }
            error={staffNumber !== undefined && !staffNumber}
          />
          <TextField
            placeholder={t('login.adminPassword', '管理者密碼')}
            type="password"
            onChange={event => setPassword(event.target.value)}
            onKeyDown={handleClickLogin}
            helperText={
              password !== undefined && !password
                ? t('login.passwordNotEntered', '密碼未輸入')
                : ''
            }
            error={password !== undefined && !password}
          />
          {/* <CheckBoxStyles>
            <Checkbox />
            <span>記住我</span>
          </CheckBoxStyles> */}
          <Button
            sx={{ padding: '12px 0' }}
            variant="contained"
            onClick={handleClickLogin}
          >
            {t('login.login', '登入')}
          </Button>
          <OperateStyles>
            {t('login.notHaveAccountYet', '尚未擁有帳號？')}
            <Box
              component={RouterLink}
              to={path.REGISTER}
              sx={{
                fontWeight: 500,
                color: theme.palette.grey[600],
                textDecoration: 'none',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {t('login.createAccount', '建立帳號')}
            </Box>
          </OperateStyles>
        </LoginMainStyles>
      </LoginBoxStyles>
    </LoginStyles>
  );
};
