import { NotificationTypes } from 'constants/notification.constants';
import { useState } from 'react';
import { Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import { useSendbirdService } from 'services/SendbirdService';
import { navigateTo } from 'utils/common';
import queryString from 'query-string';
import path from 'routes/path';
import { useMarkAsReadNotification } from 'hooks/query/notificationCenter';
import { useSnackbar } from 'notistack';
import TicketNotify from './components/ticketnotify/TicketNotify';

const { Badge, Popover, IconButton, useTheme } = Mui;

export type NormalNotification = {
  type:
    | NotificationTypes.TICKETS_NEW
    | NotificationTypes.TICKETS_ASSIGN
    | NotificationTypes.TICKETS_TRANSFER_MYSELF;
  id: string;
  isRead: boolean;
  oneClubId: string;
  ticketSn: number;
};

export type TicketTransferNotification = {
  type: NotificationTypes.TICKETS_TRANSFER_DEPARTMENT;
  departmentId: string;
  id: string;
  isRead: boolean;
};

export type LeaveOrderNotification = {
  type: NotificationTypes.LEAVE_ORDER_NEW;
  id: string;
  relatedObjectId: string;
  isRead: boolean;
};

export const TicketNotifyPopover = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { notificationList, notifyCount, isNotificationLoading } =
    useSendbirdService();
  const patchNotifications = useMarkAsReadNotification();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNotification = async (
    data:
      | NormalNotification
      | TicketTransferNotification
      | LeaveOrderNotification,
  ) => {
    let queryOptions: Record<string, string | number> = {};
    let queryPath = '';

    switch (data.type) {
      case NotificationTypes.TICKETS_NEW:
        queryOptions = { ticketNumber: data.ticketSn };
        queryPath = queryString.stringifyUrl({
          url: `${path.CUSTOMER_TICKET_MEMBER_CHANNEL_MANAGEMENT_DETAIL}/${data.oneClubId}`,
          query: queryOptions,
        });
        break;
      case NotificationTypes.TICKETS_TRANSFER_MYSELF:
        queryOptions = { tab: 'processing', tutorRange: 'myself' };
        queryPath = queryString.stringifyUrl({
          url: path.CUSTOMER_TICKET_INSTANT_CASE,
          query: queryOptions,
        });
        break;
      case NotificationTypes.TICKETS_TRANSFER_DEPARTMENT:
        queryOptions = { tab: 'processing', departmentIds: data.departmentId };
        queryPath = queryString.stringifyUrl({
          url: path.CUSTOMER_TICKET_INSTANT_CASE,
          query: queryOptions,
        });
        break;
      case NotificationTypes.LEAVE_ORDER_NEW:
        queryPath = `${path.AUDITION_LEAVE_READ}/${data.relatedObjectId}`;
        break;
      default:
        queryOptions = { tab: 'processing' };
        queryPath = queryString.stringifyUrl({
          url: path.CUSTOMER_TICKET_INSTANT_CASE,
          query: queryOptions,
        });
    }

    navigateTo(queryPath);

    if (data.isRead) return;

    const { err } = await patchNotifications.mutateAsync(data.id);
    if (err !== 0) {
      enqueueSnackbar('改變已讀失敗', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButton
        sx={{
          mr: '8px',
        }}
        onClick={handleOpen}
      >
        <Badge badgeContent={notifyCount} color="error">
          <Icon
            name={SvgName.TicketNotify}
            sx={{
              background: theme.palette.grey[600],
              cursor: 'pointer',
              width: '27px',
              height: '27px',
            }}
          />
        </Badge>
      </IconButton>

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TicketNotify
          notificationList={notificationList}
          isLoading={isNotificationLoading}
          handleOpenNotification={handleOpenNotification}
        />
      </Popover>
    </>
  );
};
