import { useMemo } from 'react';
import { Auth } from 'routes/path';
import { useRemoteConfigService } from 'services/RemoteConfigService';
import { useLoginService } from 'services/LoginService';
import { isNotNull } from 'utils/typeGuard';
import { generateDataCentralNav } from './data-central';
import { generateCustomerVisitorNav } from './customer-visitor';
import { generateAuditionNav } from './audition';
import { generateTutorialCenterNav } from './tutorial-center';
import { generateElectronicCommerceNav } from './electronic-commerce';
import { generateTeachersManagementNav } from './teachers-management';
import { generateReportNav } from './report';
import { generateFinancialNav } from './financial';
import { generateAuthorityNav } from './authority';
import { generateShipManagementNav } from './ship-management';
import { generateOneAnswerNav } from './one-answer';
import { generateAdvertisementNav } from './advertisement';
import { generateCustomerTicketNav } from './customer-ticket';

const NavConfig = () => {
  const { loginUser } = useLoginService();
  const permissions = (loginUser?.permissions || []) as Auth[];
  const { parameter } = useRemoteConfigService();

  const newNav = useMemo(() => {
    const nav = [];
    // 數據中心
    nav.push(generateDataCentralNav(permissions));

    // 會員管理
    nav.push(generateCustomerVisitorNav(permissions));

    // 課務管理
    nav.push(generateAuditionNav(permissions));

    // 補教課程管理
    nav.push(generateTutorialCenterNav(permissions));

    // 電子商務
    nav.push(generateElectronicCommerceNav(permissions));

    // 教務管理
    nav.push(generateTeachersManagementNav(permissions));

    // 統計報表
    nav.push(generateReportNav(permissions));

    // 財務管理
    nav.push(generateFinancialNav(permissions));

    // 權限管理
    nav.push(generateAuthorityNav(permissions));

    // 出貨管理
    nav.push(generateShipManagementNav(permissions));

    // OneAnswer 管理
    nav.push(generateOneAnswerNav());

    // 廣告管理
    nav.push(generateAdvertisementNav());

    // 會員案件管理
    nav.push(generateCustomerTicketNav(permissions));

    return nav.filter(isNotNull);
  }, [permissions, parameter]);

  return [
    {
      subheader: `general v${import.meta.env.VITE_APP_VERSION || '1.0.0'}`, //  general v3.3.0
      items: newNav,
    },
  ];
};

export default NavConfig;
