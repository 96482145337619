import { useState } from 'react';
import { Mui } from '@onedesign/ui';
import { Icon, SvgName } from 'components/Icon';
import { useSendbirdService } from 'services/SendbirdService';
import SendbirdTicketChannel from './components/sendbirdticketchannel/SendbirdTicketChannel';

const { Badge, Popover, IconButton, useTheme } = Mui;

export const TicketChannelPopover = () => {
  const theme = useTheme();
  const { ticketChannelUnreadCount } = useSendbirdService();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          mr: '8px',
        }}
      >
        <Badge badgeContent={ticketChannelUnreadCount} color="error">
          <Icon
            name={SvgName.TicketChannel}
            sx={{
              background: theme.palette.grey[600],
              cursor: 'pointer',
              width: '27px',
              height: '27px',
            }}
          />
        </Badge>
      </IconButton>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SendbirdTicketChannel />
      </Popover>
    </>
  );
};
