import { useState } from 'react';
import { useSendbirdService } from 'services/SendbirdService';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useLoginService } from 'services/LoginService';
import { ChannelListProvider } from '@sendbird/uikit-react/ChannelList/context';
import LineNotifyListUI from './line-notify-list-ui';

const LineNotifyList = () => {
  const [currentChannelSelected, setCurrentChannelSelected] =
    useState<GroupChannel>();
  const { loginUser } = useLoginService();
  const { sendbirdAccessToken } = useSendbirdService();

  if (!loginUser?.staffNumber || !sendbirdAccessToken) return null;

  return (
    <ChannelListProvider
      queries={{
        channelListQuery: {
          includeEmpty: true,
          customTypesFilter: [
            import.meta.env.VITE_LINE_CHANNEL_TYPE_PENDING_ID,
            import.meta.env.VITE_LINE_CHANNEL_TYPE_PROCESSING_ID,
            import.meta.env.VITE_LINE_CHANNEL_TYPE_ACCEPTED_ID,
          ],
        },
      }}
      disableAutoSelect={true}
      isTypingIndicatorEnabled={true}
    >
      <LineNotifyListUI
        setCurrentChannelSelected={setCurrentChannelSelected}
        currentChannelSelected={currentChannelSelected}
      />
    </ChannelListProvider>
  );
};

export default LineNotifyList;
