/**
 * 這個檔案是 MMS API 的入口點。
 * 它匯出一個函式，該函式接受 AxiosInstance 和 apiPrefix 兩個參數，
 * Swagger: https://api-new-dev.oneclass.co/mms/v1/api/docs/#/
 * @param axios - AxiosInstance 實例，用於發送 API 請求。
 * @param apiPrefix - API 的前綴路徑。
 */

import { AxiosInstance } from 'axios';
import credit from './credit';
import member from './member';
import order from './order';
import course from './course';
import leave from './leave';
import courseClaim from './backend/course-claim';
import studentCourse from './student-course';
import learningCoach from './learning-coach';
import feedback from './feedback';
import courseReview from './backend/course-review';

// TODO: 將oneClassApiGateWay的api整理到這裡
export default (axios: AxiosInstance, apiPrefix: string) => ({
  credit: credit(axios, apiPrefix),
  member: member(axios, apiPrefix),
  order: order(axios, apiPrefix),
  course: course(axios, apiPrefix),
  leave: leave(axios, apiPrefix),
  courseClaim: courseClaim(axios, apiPrefix),
  studentCourse: studentCourse(axios, apiPrefix),
  learningCoach: learningCoach(axios, apiPrefix),
  feedback: feedback(axios, apiPrefix),
  courseReview: courseReview(axios, apiPrefix),
});
